import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import './List.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Item from './Survey/Item';
import { Survey } from '../../../Store/AppStore';

export default class List extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'all',
      list: []
    }
    this.setFilter = this.setFilter.bind(this);
  }

  async componentDidMount() {
    try {
      let surveys = await Survey.getAllSurveys();
      this.setState({
        list: surveys
      })
    }
    catch(err) {}
  }

  setFilter(filter) {
    this.setState({filter: filter});
  }

  render() {
    return (
      <div className="Page">
        <h2>Surveys</h2>
        <div className="Header">
          <Link to="/create">
            <div className="Add">
              <FontAwesomeIcon icon={faPlus} className="Icon" /> 
              <span>Create Survey</span>
            </div>
          </Link>
          <div className="Filter">
            <button className="button" style={{backgroundColor: this.state.filter === 'all' ? "#59a5d8" : "#eef2f7"}} onClick={this.setFilter.bind(this, 'all')}>All</button>
            <button className="button" style={{backgroundColor: this.state.filter === 'processed' ? "#59a5d8" : "#eef2f7"}} onClick={this.setFilter.bind(this, 'processed')}>Processed</button>
            <button className="button" style={{backgroundColor: this.state.filter === 'ongoing' ? "#59a5d8" : "#eef2f7"}} onClick={this.setFilter.bind(this, 'ongoing')}>Ongoing</button>
          </div>
        </div>
        <div className="Surveys">
          {this.state.list.map((e) => {
            let displayProps = 'block';
            if (this.state.filter !== 'all' && this.state.filter === e.status) {
              displayProps = 'none';
            }
            return (
              <div key={e.survey_id} style={{display: displayProps}}>
                <Item title={e.title} id={e.survey_id} industry={e.industry} category={e.category} status={e.status} />
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}