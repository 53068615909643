import React, { Component } from 'react';
import '../../../Container/App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faCamera } from '@fortawesome/free-solid-svg-icons';

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stream: "",
      webcamSupport: false,
      source: "file",
      constraint: {
        video: {
          width: { min: 1024, ideal: 1280, max: 1920 },
          height: { min: 576, ideal: 720, max: 1080 },
          facingMode: 'user'
        },
        audio: true
      },
      recording: false,
      mimeType: ""
    }
    // this.handleVideo = this.handleVideo.bind(this);
    // this.videoError = this.videoError.bind(this);
    this.selectSource = this.selectSource.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.selectFiles = this.selectFiles.bind(this);
    this.submit = this.submit.bind(this);
    this.webcam = React.createRef(null);
    this.uploadFile = React.createRef(null);
    this.stream = null;
    this.recording = null;
    this.data = [];
    this.file = null;
  }

  async componentDidMount() {
    let webcamAllowed = false;
    let options = {mimeType: 'video/webm'};
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      try {
        await navigator.mediaDevices.getUserMedia(this.state.constraint);
        webcamAllowed = true;
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          options = {mimeType: 'video/webm; codecs=vp9'};
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
          options = {mimeType: 'video/webm; codecs=vp8'};
        } else {
          options = {mimeType: 'video/webm'};
        }
      }
      catch(err) {
      }
    }
    this.setState({
      webcamSupport: webcamAllowed,
      mimeType: options
    })
    // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
    // if (navigator.getUserMedia) {
    //   navigator.getUserMedia({video: true}, this.handleVideo, this.videoError);
    // }
  }

  async selectSource(source) {
    if (source == "webcam" && !this.state.webcamSupport) {
      return true;
    }
    if (source == "webcam") {
      try {
        let stream = await navigator.mediaDevices.getUserMedia(this.state.constraint);
        this.webcam.current.srcObject = stream;
        this.setState({
          stream: stream
        })
        this.stream = stream;
      }
      catch(err) {

      }
    }
    else {
      this.webcam.current.srcObject = null;
      this.stream = null;
    }
    this.setState({
      source: source
    })
  }

  startRecording() {
    this.file = null;
    this.recording = new MediaRecorder(this.stream, this.state.mimeType);
    this.data = [];
    this.recording.ondataavailable((event) => {
      this.data.push(event.data);
    });
    this.recording.start();
    this.setState({
      recording: true
    })
  }

  stopRecording() {
    this.recording.onstop((event) => {
      this.file = new Blob(this.data, {type: 'video/webm'})
    })
    this.recording.stop();
    this.setState({
      recording: false
    })
  }

  selectFiles() {
    this.file = new File([this.uploadFile.current.files[0]] , this.uploadFile.current.files[0].name , { type: this.uploadFile.current.files[0].type });
  }

  submit() {
    this.props.submitVideo(this.file);
  }

  // handleVideo(stream) {
  //   this.setState({ stream: stream });
  //   this.webcam.current.srcObject = stream;
  // }

  // videoError() {

  // }

  render () {
    return (
      <div className="Page">
        <h3>{this.props.question}</h3>
        <div className="options">
          <div className={this.state.source == "file" ? "option-select active" : "option-select"} onClick={this.selectSource.bind(this, 'file')}>
            File Upload
          </div>
          <div className={this.state.source == "webcam" ? "option-select active" : "option-select"} onClick={this.selectSource.bind(this, 'webcam')}>
            Webcam
          </div>
        </div>
        {this.state.source === "webcam" ? (
          <div className="answer-content">
            {this.state.webcamSupport ? (
              <div className="webcam">
                <div className="videoContainer">
                  <video className="videoPlay" autoPlay ref={this.webcam} >
                    <source src={this.state.stream} />
                  </video>
                </div>
                <div className="Controls">
                  <div className="Action">
                    <div className="Action" onClick={this.shiftCamera}>
                      <FontAwesomeIcon icon={faCamera} className="Icon" style={{color: "#6c757d"}} />
                      <span>Change Camera</span>
                    </div>
                  </div>
                  {this.state.recording ? (
                    <div className="Action" onClick={this.stopRecording}>
                      <FontAwesomeIcon icon={faStop} className="Icon" style={{color: "#fa5c7c"}} />
                      <span>Stop Recording</span>
                    </div>
                  ) : (
                    <div className="Action" onClick={this.startRecording}>
                      <FontAwesomeIcon icon={faPlay} className="Icon" style={{color: "#0acf97"}} />
                      <span>Start Recording</span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <span>Not Supported</span>
            )}  
          </div>
        ) : (
          <input type="file" ref={this.uploadFile} className="input" accept="video/mp4,video/webm" onChange={this.selectFiles} style={{marginTop: '5rem'}} />
        )}
        <button className="button" style={{backgroundColor: "#59a5d8", marginTop: "5rem"}} onClick={this.submit}>Submit</button>
      </div>
    )
  }
}