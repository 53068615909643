import React, { Component } from 'react';
import '../../Container/App.css';
import VideoAnswer from '../Answer/Answers/Video';
import Sidebar from '../Answer/QuestionBar/Sidebar';
import { Questionaire } from '../../Store/AppStore';

function makeid(num) {
  let id = "";
  for(let i = 0; i < num; i++) {
    id = id + parseInt(Math.round(Math.random()*9))
  }
  return id;
}

export default class App extends Component {
  constructor(props) {
    super(props);
    const querySearch = window.location.search;
    const urlParam = new URLSearchParams(querySearch);
    this.state = {
      project_id: urlParam.has('project_id') ? urlParam.get('project_id') : null,
      track_id: urlParam.has('track_id') ? urlParam.get('track_id') : null,
      survey_id: urlParam.has('survey_id') ? urlParam.get('survey_id') : null,
      title: "",
      questions: [],
      question_id: null,
      curr_question: ""
    }
    this.submitVideo = this.submitVideo.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);
  }

  async componentDidMount() {
    try {
      let survey = await Questionaire.getSurvey(this.state.survey_id, this.state.track_id, this.state.project_id);
      let questions = [];
      survey.questions.forEach((e) => {
        questions.push(Object.assign({}, e));
      })
      this.setState({
        title: survey.title,
        questions: questions,
        question_id: questions[0]["id"],
        curr_question: questions[0]["question"]
      })
    }
    catch(err) {}
  }

  submitVideo(data) {
    this.setState((state, props) => {
      let question = state.questions;
      for (let i = 0; i < question.length; i++) {
        if (question[i]["id"] == state.question_id) {
          question[i]["answer"]["status"] = "1";
        }
      }
      return {
        questions: question
      }
    });
    Questionaire.submitAnswer({
      question_id: this.state.question_id,
      project_id: this.state.project_id,
      track_id: this.state.track_id,
      filename: data.name || "user_capture_" + makeid(6) + ".webm",
      file: data
    }).then((res) => {
      this.setState((state, props) => {
        let question = state.questions;
        for (let i = 0; i < question.length; i++) {
          if (question[i]["id"] == state.question_id) {
            question[i]["answer"]["status"] = res.success ? "2" : "-1";
            question[i]["answer"]["url"] = res.success ? res.url : "";
          }
        }
        return {
          question: question
        }
      });
    }).catch((rej) => {

    });
  }

  changeQuestion(id, name) {
    this.setState({
      question_id: id,
      curr_question: name
    })
  }

  render() {
    return (
      <div>
        {this.state.questions.length > 0 ? (
          <div className="Container">
            <div className="Sidebar">
              <Sidebar questions={this.state.questions} question_id={this.state.question_id} changeQuestion={this.changeQuestion} />
            </div>
            <div className="Content">
              <VideoAnswer question={this.state.curr_question} submitVideo={this.submitVideo} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}