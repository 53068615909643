import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navigator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { IoIosBrowsers, IoIosPaper, IoMdAnalytics, IoIosAddCircleOutline } from 'react-icons/io'
import bgImg from '../../Assets/waves.png';

export default (props) => {
  return (
    <div className="Navigator">
      <div className="User" style={{backgroundImage: `url(${bgImg})`}}>
        <FontAwesomeIcon icon={faUserCircle} className="Profile" />
        <span className="Name">{props.name}</span>
      </div>
      <ul className="Nav-List">
        <li className="List-Title">
          Survey
        </li>
        <li className="List-Item">
          <NavLink activeClassName="Active-Link" to="/manage">
            <div className="List-Link">
              <IoIosBrowsers />
              <span>List</span>
            </div>
          </NavLink>
          {/* {props.survey_id === "" ? (
            <ul className="Nav-List">
              <li className="List-Item">
                <div className="List-Link Inactive">
                  <IoIosPaper />
                  <span>Detail</span>
                </div>
              </li>
            </ul>
          ) : (
            <ul className="Nav-List">
              <li className="List-Item">
                <NavLink activeClassName="Active-Link" to={`/manage/${props.survey_id}`}>
                  <div className="List-Link">
                    <IoIosPaper />
                    <span>Detail</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          )} */}
        </li>
        <li className="List-Item">
          <NavLink activeClassName="Active-Link" to="/create">
            <div className="List-Link">
              <IoIosAddCircleOutline />
              <span>Create</span>
            </div>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}