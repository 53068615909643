import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import './App.css';
import Survey from '../Components/SurveySubmit/Survey';
import Login from '../Components/Auth/Login';
import Signup from '../Components/Auth/SignUp';
import { Authorization } from '../Store/AppStore';
import Sidebar from '../Components/Answer/QuestionBar/Sidebar';

class App extends Component {
  constructor(props) {
    super(props);
    const querySearch = window.location.search;
    this.state = {
      isAuthenticated: false,
      original_location: "/survey"+querySearch
    }
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    let path = this.props.location.pathname;
    if (path.indexOf('survey') < 0) {
      path = "/survey";
    }
    let auth = await Authorization.isAuthenticated();
    if (auth) {
      this.setState({
        isAuthenticated: true,
        original_location: path
      });
      this.props.history.replace(path);
    }
    else {
      this.setState({
        original_location: path
      })
      this.props.history.replace('/login');
    }
  }

  async logout() {
    await Authorization.logout();
    this.setState({
      isAuthenticated: false
    });
    this.props.history.replace('/login');
  }

  async login(email, password) {
    try {
      await Authorization.login(email, password);
      this.props.history.replace(this.state.original_location);
      this.setState({
        isAuthenticated: true
      })
    }
    catch(err) {
      console.log(err);
    }
  }

  async signup(email, password, dob) {
    try {
      await Authorization.register(email, password, dob);
      this.props.history.push('/login');
    }
    catch(err) {
    }
  }

  render() {
    return (
      <div className="App">
        <Header isAuthenticated={this.state.isAuthenticated} logout={this.logout} />
        {this.state.isAuthenticated ? (
          <Switch>
            <Route path="/survey" render={(props) => (
              <Survey {...props} />
            )} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/login" render={(props) => (
              <div className="Container">
                <div className="Content">
                  <Login {...props} login={this.login} />
                </div>
              </div>
            )} />
            <Route exact path="/signup" render={(props) => (
              <div className="Container">
                <div className="Content">
                  <Signup {...props} signup={this.signup} />
                </div>
              </div>
            )} />
          </Switch>
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);