import React, { PureComponent } from 'react';
import './Detail.css';
import VideoConsole from './Type/Videos';
import { Survey } from '../../../Store/AppStore';
import { createSurvey } from '../../../Store/API';

const makeId = (num) => {
  let id = "";
  for(let i = 0; i < num; i++) {
    let d = parseInt(Math.round(Math.random() * 9));
    id = id + d;
  }
  return id;
}

export default class Detail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      survey_id: parseInt(this.props.match.params.survey_id),
      title: "",
      industry: "",
      category: "",
      current_question: "",
      current_question_text: "", 
      current_type: "Video",
      current_answer: [],
      status_present: [],
      questions: [],
      answers: {},
      forceUpdate: [],
      project_id: "",
      track_id: "",
      pipeline_status: 0,
      any_approved: false
    }
    this.changeQuestion = this.changeQuestion.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
    this.approveVideo = this.approveVideo.bind(this);
    this.processVideos = this.processVideos.bind(this);
  }

  async componentDidMount() {
    let currSurvey = Survey.getSurvey(this.props.match.params.survey_id);
    let questions = [];
    try {
      let project_id = await Survey.getProjectId();
      let track_id = await Survey.getTrackId();
      let pipeStatus = await Survey.getPipeLine({
        survey_id: this.props.match.params.survey_id
      })
      if (currSurvey.hasOwnProperty('questions')) {
        currSurvey.questions.forEach(element => {
          questions.push({
            question_id: element.id,
            question: element.question,
            type: "Video"
          });
        });
        this.setState({
          survey_id: this.props.match.params.survey_id,
          project_id: project_id,
          track_id: track_id,
          title: currSurvey.title,
          industry: currSurvey.industry,
          category: currSurvey.category,
          keyword: createSurvey.keyword,
          current_question: questions.length > 0 ? questions[0]["question_id"] : 0,
          current_question_text: questions.length > 0 ? questions[0]["question"] : "",
          current_type: "Video",
          current_answer: [],
          questions: questions,
          answers: {},
          pipeline_status: pipeStatus.success ? pipeStatus.data.value[0].status_id : 0
        })
    
        let event = {
          target: {
            value: questions.length > 0 ? questions[0]["question_id"] : 0
          }
        }
        await this.changeQuestion(event);
      }
    }
    catch(err) {}
  }

  approveVideo(ids) {
    Survey.approveVideo({
      survey_id: this.state.survey_id,
      question_id: this.state.current_question,
      videoIds: ids
    }).then((data) => {
      if (data.success) {
        this.setState((state, props) => {
          let ans = [];
          state.current_answer.forEach((e) => {
            if (ids.indexOf(e.id) >= 0) {
              ans.push(Object.assign({}, e, {status: "1"}));
            }
            else {
              ans.push(Object.assign({}, e));
            }
          })
          let answers = state.answers;
          answers[this.state.current_question] = ans;
          return {
            answers: answers,
            current_answer: ans,
            any_approved: true
          }
        })
      }
    }).catch((err) => {})
  }

  async processVideos() {
    try {
      let resp = await Survey.processVideos({
        survey_id: this.state.survey_id,
        question_id: this.state.current_question
      });
      if (resp.success) {
        this.setState((state, props) => {
          let ans = [];
          state.current_answer.forEach((e) => {
            if (e.status == "1") {
              ans.push(Object.assign({}, e, {status: "2"}));
            }
            else {
              ans.push(Object.assign({}, e));
            }
          })
          let answers = state.answers;
          answers[this.state.current_question] = ans;
          return {
            answers: answers,
            current_answer: ans,
            pipeline_status: 1
          }
        })
      }
    }
    catch(err) {}
  }

  async uploadVideo(files) {
    let promiseArr = [];
    let currQuestionId = this.state.current_question;
    let answers = [];
    files.forEach((e) => {
      let v_id = "add_abhi_" + makeId(3);
      promiseArr.push(Survey.uploadVideo({
        question_id: currQuestionId,
        survey_id: this.state.survey_id,
        fileName: makeId(3) + "_" + encodeURI(e.name),
        file: e,
        temp_vid_id: v_id
      }));
      answers.push({
        id: v_id,
        url: "",
        status: "-1",
        thumbnail: false
      })
    });
    this.setState((state, props) => {
      let curr_ans = state.current_answer.concat(answers);
      let forceUpdate = [...state.forceUpdate];
      if (forceUpdate.indexOf(currQuestionId) < 0) {
        forceUpdate.push(currQuestionId);
      }
      let ans = {};
      ans[currQuestionId] = curr_ans;
      let newAns = Object.assign({}, state.answers, ans);
      return {
        forceUpdate: forceUpdate,
        current_answer: curr_ans,
        answers: newAns
      }
    });
    try {
      await Promise.all(promiseArr);
      await Survey.getAllVideos({
        survey_id: this.state.survey_id,
        question_id: parseInt(currQuestionId)
      });
    }
    catch(err) {}
    if (currQuestionId === this.state.current_question) {
      this.changeQuestion({
        target: {
          value: currQuestionId
        }
      });
    }
  }

  async changeQuestion(event) {
    if (this.state.answers.hasOwnProperty(event.target.value) && this.state.forceUpdate.indexOf(event.target.value) < 0) {
      this.setState((state, props) => {
        let status_present = [];
        let question_text = "";
        for(let i = 0; i < state.questions.length; i++) {
          if (state.questions[i]["question_id"] == event.target.value) {
            question_text = state.questions[i]["question"];
            break;
          }
        }
        state.answers[event.target.value].forEach((e) => {
          if (status_present.indexOf(e.status) < 0) {
            status_present.push(e.status);
          }
        });
        let initValue = status_present.indexOf(1) >= 0 || status_present.indexOf(2) >= 0 || status_present.indexOf(3) >= 0 || status_present.indexOf(4) >= 0;
        return {
          current_question: event.target.value,
          current_question_text: question_text,
          current_answer: state.answers[event.target.value],
          status_present: status_present,
          any_approved: initValue
        }
      });
    }
    else {
      let q_id = event.target.value;
      try {
        let data = await Survey.getAllVideos({
          survey_id: this.state.survey_id,
          question_id: parseInt(q_id)
        });
        this.setState((state, props) => {
          let forceUpdate = state.forceUpdate;
          let index = forceUpdate.indexOf(q_id);
          if (index >= 0) {
            forceUpdate = state.forceUpdate.slice(index, 1);
          }
          let question_text = "";
          for(let i = 0; i < state.questions.length; i++) {
            if (state.questions[i]["question_id"] == q_id) {
              question_text = state.questions[i]["question"];
              break;
            }
          }
          let ans = {};
          ans[q_id] = data;
          let status_present = [];
          data.forEach((e) => {
            if (status_present.indexOf(e.status) < 0) {
              status_present.push(e.status);
            }
          });
          let newAnswer = Object.assign({}, state.answers, ans);
          return {
            answers: newAnswer,
            current_question: q_id,
            current_question_text: question_text,
            current_answer: newAnswer[q_id],
            forceUpdate: forceUpdate,
            status_present: status_present
          }
        });
      }
      catch(err) {}
    }
  }

  render() {
    return (
      <div className="Page">
        <h2>Details: {this.state.title}</h2>
        <h3>Select Question</h3>
        <select className="input" value={this.state.current_question} onChange={this.changeQuestion}>
          {this.state.questions.map((e) => (
            <option key={e.question_id} value={e.question_id}>{e.question}</option>
          ))}
        </select>
        <VideoConsole question_text={this.state.current_question_text} list={this.state.current_answer} statuses={this.state.status_present} project_id={this.state.project_id} track_id={this.state.track_id} survey_id={this.state.survey_id} question_id={this.state.current_question} uploadVideo={this.uploadVideo} approveVideo={this.approveVideo} processVideos={this.processVideos} pipelineStatus={this.state.pipeline_status} anyApproved={this.state.any_approved} />
      </div>
    );
  }
}