import React from 'react';
import '../Create.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  function deleteQuestion(index) {
    props.removeQuestion(index);
  }

  return (
    <div className="Entry">
      <h3>Questions</h3>
      {props.list.map((e, i) => (
        <div key={e.question} className="Entry">
          <div className="Question">
            <div className="Entry">
              <label>Question</label>
              <input className="underline-input" onChange={(e) => {props.updateQuestion(e.target.value, i)}} value={e.question} autoFocus={props.focus === i}/>
            </div>
            <div className="Entry">
              <label>Type</label>
              <select vaue="Video">
                <option value="Video">Video</option>
              </select>
            </div>
            <div className="Entry-Right">
              <div className="Delete">
                <FontAwesomeIcon icon={faTrash} onClick={deleteQuestion.bind(undefined, i)} className="Delete-Icon" />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="Entry">
        <div className="Add" onClick={props.addQuestion}>
          <FontAwesomeIcon icon={faPlus} className="Icon" />
          <span>Add Question</span>
        </div>
      </div>
    </div>
  )
}