import React from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  return (
    <header className="Header">
      <div className="Brand">
        <div className="Logo-1"></div>
        <div className="Logo-2"></div>
        <div className="Text">VIDSIGHT</div>
        <sub className="Sub">By Enumerate</sub>
      </div>
      {props.isAuthenticated ? (
        <div className="Logout" onClick={props.logout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="Icon" />
          <span className="Text">Logout</span>
        </div>
      ) : null}
    </header>
  )
}