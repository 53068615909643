import React,  { useState, useRef, useEffect } from 'react';
import '../Detail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faWindowClose, faPlay, faCircle, faCogs, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { IoMdAnalytics } from 'react-icons/io';
import { Ellipsis } from 'react-spinners-css';
import { Authorization } from '../../../../Store/AppStore';

const videoStatusMap = {
  "-1": {
    id: "uploading",
    name: "Uploading",
    color: "#6c757d"
  },
  "0": {
    id: "uploaded",
    name: "Uploaded",
    color: "#6c757d"
  },
  "1": {
    id: "approved",
    name: "Approved",
    color: "#0acf97"
  },
  "2": {
    id: "processing",
    name: "Processing",
    color: "#fa5c7c"
  },
  "3": {
    id: "processed",
    name: "Done",
    color: "#0acf97"
  },
  "4": {
    id: "error",
    name: "Error",
    color: "#0acf97"
  }
}

export default (props) => {
  var [filter, setFilter] = useState('all');
  var [selected, setSelected] = useState([]);
  var [toggle, setToggle] = useState(false);
  var videoPlay = useRef(null);
  var videoSlider = useRef(null);
  var uploadFile = useRef(null);
  var getToken = encodeURIComponent(Authorization.getToken());

  useEffect(() => {
    props.list.forEach((e) => {
      if (e.url) {
        let thumbSource = document.createElement("source");
        thumbSource.src = e.url + "#t=20";
        let thumbVideo = document.createElement("video");
        thumbVideo.addEventListener("loadeddata", function() {
          let canvas = document.createElement("canvas");
          let container = document.querySelector(`#video-answer-${e.id} .Thumbnail`);
          let width = container.clientWidth;
          let height = container.clientHeight;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(thumbVideo, 0, 0, canvas.width, canvas.height);
          container.appendChild(canvas);
        }, false);
        thumbVideo.appendChild(thumbSource);
      }
    });
  })

  function selectAllVideo() {
    let newSelected = [...selected];
    let prompt = false;
    if (toggle) {
      if (filter === "all") {
          newSelected = [];
      }
      else {
        props.list.forEach((e) => {
          if (filter == e.status && newSelected.indexOf(e.id) >= 0) {
            newSelected.splice(newSelected.indexOf(e.id), 1);
          }
        });
      }
    }
    else {
      props.list.forEach((e) => {
        if (filter == "all" || (filter == e.status && newSelected.indexOf(e.id) < 0)) {
          newSelected.push(e.id);
          if (e.status > 1) {
            prompt = true;
          }
        }
      });
    }
    let returnValue = true;
    if (prompt) {
      returnValue = window.prompt("You have selected processed videos. Do you still want to reprocess it?");
    }
    if (returnValue) {
      setToggle(!toggle);
      setSelected(newSelected);
    }
  }

  function approveVideos() {
    if (selected.length > 0) {
      props.approveVideo(selected);
      setSelected([]);
    }
  }

  function processVideos() {
    if(props.anyApproved && (props.pipelineStatus >= 4 || props.pipelineStatus == 0)) {
      props.processVideos();
    }
  }

  function selectVideo(id, status) {
    let returnValue = true;
    if (status > 1 && selected.indexOf(id) < 0) {
      returnValue = window.prompt("This video is already processed. Do you still want to reprocess it?");
    }
    if (status <= 1 || returnValue) {
      let newSelected = [...selected];
      let index = newSelected.indexOf(id);
      if (index < 0) {
        newSelected.push(id);
      }
      else {
        newSelected.splice(index, 1);
      }
      setSelected(newSelected);
    }
  }

  function showVideo(uri) {
    if (uri) {
      videoSlider.current.style.display = 'block';
      var source = videoPlay.current.firstElementChild;
      videoPlay.current.pause();
      source.setAttribute('src', uri);
      videoPlay.current.load();
      videoPlay.current.play();
    }
  }

  function hideVideo() {
    videoPlay.current.pause();
    videoSlider.current.style.display = 'none';
  }

  function selectFiles() {
    let files = [];
    if (uploadFile.current.value) {
      for(let i = 0; i < uploadFile.current.files.length; i++) {
        let file = new File([uploadFile.current.files[i]] , uploadFile.current.files[i].name , { type: uploadFile.current.files[i].type });
        files.push(file);
      }
    }
    if (files.length > 0) {
      props.uploadVideo(files);
    }
  }

  function openFileUploader() {
    uploadFile.current.click();
  }

  return (
    <div className="Survey" style={{marginTop: '2rem'}}>
      <div className="Header">
        <div className="Add" onClick={openFileUploader}>
          <FontAwesomeIcon icon={faUpload} className="Icon" /> 
          <span>Upload Videos</span>
          <input type="file" ref={uploadFile} multiple style={{display: 'none'}} accept="video/mp4,video/webm" onChange={selectFiles} />
        </div>
        <div className="Filter">
          <button className="button" style={{backgroundColor: filter === 'all' ? "#59a5d8" : "#eef2f7"}} onClick={() => setFilter('all')}>All</button>
          {Object.keys(videoStatusMap).map((e) => {
            return props.statuses.indexOf(parseInt(e)) >= 0 ? (
              <button key={e} className="button" style={{backgroundColor: filter === `${e}` ? "#59a5d8" : "#eef2f7"}} onClick={() => setFilter(`${e}`)}>{videoStatusMap[e]["name"]}</button>
            ) : null;
          })}
          <a target="_blank" className="Analytics" href={`https://dashboard.enumerate.ai/?batchId=${props.survey_id}&questionId=${props.question_id}&token=${getToken}&question=${encodeURI(props.question_text)}`}>
            <IoMdAnalytics className="Icon" />
            <span>Analytics</span>
          </a>
        </div>
      </div>
      <div className="Header">
        <div className="Select-All" onClick={selectAllVideo}>
          <FontAwesomeIcon icon={faCircle} className="Icon" style={toggle ? { color: "#59a5d8" } : { color: "#ffffff" }} /> 
          <span>Select All</span>
        </div>
        <div className="Survey-Link">
          <input type='text' className="input" value={`https://videoapp.enumerate.ai/?project_id=${props.project_id}&track_id=${props.track_id}&survey_id=${props.survey_id}`} readOnly />
        </div>
        <div className="Filter">
          <div className={selected.length > 0 ? "Approve" : "Approve Disable"} onClick={approveVideos}>
            <FontAwesomeIcon icon={faCheck} className="Icon" /> 
            <span>Approve for Process</span>
          </div>
          <div className={props.anyApproved && (props.pipelineStatus >= 4 || props.pipelineStatus == 0) ? "Process" : "Process Disable"} onClick={processVideos}>
            <FontAwesomeIcon icon={faCogs} className="Icon" /> 
            {props.pipelineStatus >= 4 || props.pipelineStatus == 0 ? (
              <span>Start Processing</span>
            ) : (
              <span>Processing In Progress</span>
            )}
          </div>
        </div>
      </div>
      <div className="Console">
        {props.list.map((e) => {
          let displayProps = 'inline-block';
          if (filter !== 'all' && filter != e.status) {
            displayProps = 'none';
          }
          let classNameToGive = "Video";
          if (selected.indexOf(e.id) >= 0) {
            classNameToGive = "Video Selected";
          }
          if (e.status == "-1") {
            return (
              <div key={e.id} className="no-video" style={{display: displayProps}}>
                <div className="Thumbnail">
                  <Ellipsis className="ThumbIcon" style={{color: "#59a5d8"}} />
                </div>
                <div className="Status" style={{color: videoStatusMap[e.status]["color"]}}>
                  {videoStatusMap[e.status]["name"]}
                </div>
              </div>
            );
          }
          else {
            return (
              <div key={e.id} id={`video-answer-${e.id}`} className={classNameToGive} style={{display: displayProps}}>
                {e.thumbnail ? (
                  <img src={e.thumbnail} className="Thumbnail" />
                ) : (
                  <div className="Thumbnail">
                    {/* <FontAwesomeIcon icon={faVideo} className="ThumbIcon" /> */}
                  </div>
                )}
                <div className="Status" style={{color: videoStatusMap[e.status]["color"]}}>
                  {videoStatusMap[e.status]["name"]}
                </div>
                <div className="Video-Play">
                  <FontAwesomeIcon icon={faPlayCircle} />
                </div>
                <div className="Overlay">
                  <div className="Overlay-button" onClick={() => {selectVideo(e.id, e.status)}}>
                    <FontAwesomeIcon icon={faCircle} className="Icon" style={ selected.indexOf(e.id) >= 0 ? { color: "#59a5d8" } : { color: "#dee2e6" }} />
                    <span className="Overlay-text">Select</span>
                  </div>
                  <div className="Overlay-button" onClick={() => showVideo(e.url)}>
                    <FontAwesomeIcon icon={faPlay} className="Icon" style={{color: "#59a5d8"}} />
                    <span className="Overlay-text">Play</span>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className="VideoPlay" ref={videoSlider}>
        <div className="Close" onClick={hideVideo}>
          <FontAwesomeIcon icon={faWindowClose} style={{fontSize: '2rem'}} />
        </div>
        <div className="videoContainer">
          <video className="videoContainer" ref={videoPlay} controls controlsList="nodownload">
            <source src="" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}