import React, {useState} from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  var [email, setEmail] = useState("");
  var [password, setPassword] = useState("");

  function login() {
    if (email && password) {
      props.login(email, password);
    }
  }

  return (
    <div className="Auth">
      <div className="Entry">
        <label>Email</label>
        <input className="input" type="text" onChange={(event) => {setEmail(event.target.value)}} value={email} />
      </div>
      <div className="Entry">
        <label>Password</label>
        <input className="input" type="password" onChange={(event) => {setPassword(event.target.value)}} value={password} />
      </div>
      <div className="Entry">
        <button className="button" onClick={login}>Log In</button>
      </div>
      <div className="Entry">
        Not a member? <Link to="/signup">SignUp</Link>
      </div>
    </div>
  );
}