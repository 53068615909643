import React from 'react';
import '../../Navigator/Navigator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bgImg from '../../../Assets/waves.png';
import { Spinner } from 'react-spinners-css';
import { faCircle, faCheck, faUserCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const AnsMapping = {
  "-1": {
    name: "error",
    component: (
      <FontAwesomeIcon icon={faTimes} className="Icon" style={{color: "#fa5c7c"}} />
    )
  },
  "0": {
    name: "not_answered",
    component: (
      <FontAwesomeIcon icon={faCircle} className="Icon" style={{color: "#6c757d"}} />
    )
  },
  "1": {
    name: "uploading",
    component: (
      <Spinner size={12} color="#59a5d8" />
    )
  },
  "2": {
    name: "submitted",
    component: (
      <FontAwesomeIcon icon={faCheck} className="Icon" style={{color: "#0acf97"}} />
    )
  }
}

export default (props) => {
  return (
    <div className="Navigator">
      <div className="User" style={{backgroundImage: `url(${bgImg})`}}>
        <FontAwesomeIcon icon={faUserCircle} className="Profile" />
        <span className="Name">{props.name}</span>
      </div>
      <ul className="Nav-List Question-List">
        <li className="List-Title" style={{marginLeft: '1rem'}}>
          Questions
        </li>
        {props.questions.map((e, i) => {
          let className = "Question-Item";
          if (e.id == props.question_id) {
            className = "Question-Item Active";
          }
          return (
            <li key={e.id}>
              <div className={className}>
                <span>Question {i+1}</span>
                {AnsMapping[e.answer.status]["component"]}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}