import React, {useState} from 'react';

export default (props) => {
  var [email, setEmail] = useState("");
  var [password, setPassword] = useState("");
  var [date, setDate] = useState("");

  function signup() {
    if (email && password && date) {
      props.signup(email, password, date);
    }
  }

  return (
    <div className="Auth">
      <div className="Entry">
        Welcome to Enumerate.ai
      </div>
      <div className="Entry">
        <label>Email</label>
        <input className="input" type="text" onChange={(event) => {setEmail(event.target.value)}} value={email} />
      </div>
      <div className="Entry">
        <label>Password</label>
        <input className="input" type="password" onChange={(event) => {setPassword(event.target.value)}} value={password} />
      </div>
      <div className="Entry">
        <label>Date Of Birth</label>
        <input className="input" type="date" onChange={(event) => {setDate(event.target.value)}} value={date} />
      </div>
      <div className="Entry">
        <button className="button" onClick={signup}>Register</button>
      </div>
    </div>
  );
}