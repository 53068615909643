import React, { PureComponent } from 'react';
import './Create.css';
import QuestionList from './QuestionList/List';
import { Survey } from '../../Store/AppStore';

export default class Create extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      survey_id: "", // if it is edit
      title: "",
      keyword: "",
      industry: "",
      category: "",
      product: "",
      questions: [],
      industryList: [],
      categoryList: [],
      productList: [],
      focusId: 0,
      mapping: {
        industry: {}
      }
    }
    this.addQuestion = this.addQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.updateKey = this.updateKey.bind(this);
    this.checkEntry = this.checkEntry.bind(this);
    this.createSurvey = this.createSurvey.bind(this);
    this.updateSelectionType = this.updateSelectionType.bind(this);
  }

  async componentDidMount() {
    try {
      let data = await Survey.getIndustryCategoryProduct();
      if (data.status) {
        let industryList = [];
        Object.keys(data.industry).forEach((e) => {
          let obj = data.industry[e];
          industryList.push({
            id: obj.id,
            name: obj.name
          });
        })
        this.setState({
          mapping: data,
          industryList: industryList
        });
        this.updateSelectionType('industry', {
          target: {
            value: industryList[0]["id"]
          }
        })
      }
    }
    catch(e) {
    }
  }

  updateSelectionType(key, event) {
    let industry = this.state.industry;
    let category = this.state.category;
    let product = this.state.product;
    let value = event.target.value;
    if (key === 'industry') {
      let categoryList = [];
      let categoryMapList = this.state.mapping.industry[value].category;
      Object.keys(categoryMapList).forEach((e) => {
        categoryList.push({
          id: categoryMapList[e]["id"],
          name: categoryMapList[e]["name"]
        })
      });
      this.setState({
        industry: value,
        categoryList: categoryList,
        category: categoryList.length > 0 ? categoryList[0]["id"] : "",
        product: "",
        productList: []
      });
    }
    else if (key === 'category') {
      let productList = [];
      let productMapList = this.state.mapping.industry[this.state.industry].category[value].product;
      Object.keys(productMapList).forEach((e) => {
        productList.push({
          id: productMapList[e]["id"],
          name: productMapList[e]["name"]
        })
      });
      this.setState({
        category: value,
        product: productList.length > 0 ? productList[0]["id"] : "",
        productList: productList
      });
    }
    else {
      this.setState({
        product: value
      });
    }
  }

  updateKey(key, event) {
    var object = {};
    object[key] = event.target.value;
    this.setState(object);
  }

  updateQuestion(text, id) {
    this.setState((state, props) => {
      let questions = state.questions.slice();
      questions[id].question = text;
      return {
        questions: questions,
        focusId: id
      }
    })
  }

  removeQuestion(id) {
    this.setState((state, props) => {
      let questions = state.questions.splice(id, 1);
      return {
        questions: questions
      }
    })
  }

  addQuestion() {
    this.setState((state, props) => {
      let questions = state.questions.slice();
      questions.push({
        question: "",
        type: "Video"
      });
      return {
        questions: questions,
        focusId: questions.length - 1
      }
    })
  }

  checkEntry() {
    if(this.state.title === "") {
      return true;
    }
    if (this.state.questions.length === 0) {
      return true;
    }
    return false;
  }

  async createSurvey() {
    try {
      let data = await Survey.createNewSurvey({
        title: this.state.title,
        keyword: this.state.keyword,
        industryId: this.state.industry || 0,
        categoryId: this.state.category || 0,
        productId: this.state.product || 0
      });
      if (data.success) {
        let survey_id = data.survey_id;
        let promiseQuestions = [];
        this.state.questions.forEach((e) => {
          promiseQuestions.push(Survey.createNewQuestion({
            survey_id: survey_id,
            question: e.question
          }));
        });
        Promise.all(promiseQuestions).then(data => {
          this.props.history.push('/manage');
        }).catch((err) => {
          console.log(err);
        });
      }
    }
    catch(err) {}
  }

  render() {
    return (
      <div className="Page">
        <h2>Create Survey</h2>
        <div className="Entry">
          <label htmlFor="title">Title</label>
          <input id="title" className="input" type="text" onChange={this.updateKey.bind(this, 'title')} />
        </div>
        <div className="Entry">
          <label htmlFor="industry">Select Industry</label>
          <select id="industry" onChange={this.updateSelectionType.bind(this, 'industry')} value={this.state.industry}>
            {this.state.industryList.map((e) => (
              <option key={e.id} value={e.id}>{e.name}</option>
            ))}
          </select>
        </div>
        <div className="Entry">
          <label htmlFor="category">Select Category</label>
          <select id="category" onChange={this.updateSelectionType.bind(this, 'category')} value={this.state.category}>
            {this.state.categoryList.map((e) => (
              <option key={e.id} value={e.id}>{e.name}</option>
            ))}
          </select>
        </div>
        {/* <div className="Entry">
          <label htmlFor="category">Select Product</label>
          <select id="category" onChange={this.updateSelectionType.bind(this, 'product')} value={this.state.product}>
            {this.state.productList.map((e) => (
              <option key={e.id} value={e.id}>{e.name}</option>
            ))}
          </select>
        </div> */}
        <div className="Entry">
          <label htmlFor="keyword">Keyword</label>
          <textarea id="keyword" className="input" rows="2" onChange={this.updateKey.bind(this, 'keyword')} placeholder="Comma separated list of specific keywords; only the below mentioned keywords will be used to perform analysis" />
        </div>
        <QuestionList focus={this.state.focusId} list={this.state.questions} removeQuestion={this.removeQuestion} updateQuestion={this.updateQuestion} addQuestion={this.addQuestion} />
        <div className="Entry-Right">
          <button className="Button" onClick={this.createSurvey} disabled={this.checkEntry()}>Submit</button>
        </div>
      </div>
    );
  }
}