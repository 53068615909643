import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import './App.css';
import Navigation from '../Components/Navigator/Navigator';
import Create from "../Components/CreateSurvey/Create";
import Surveys from '../Components/SurveyManager/SurveyList/List';
import Detail from '../Components/SurveyManager/Manager/Detail';
import Login from '../Components/Auth/Login';
import Signup from '../Components/Auth/SignUp';
import { Authorization, Survey } from '../Store/AppStore';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      project_id: "",
      track_id: "",
      survey_id: "",
      original_location: "/manage"
    }
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    let path = this.props.location.pathname;
    if (path.indexOf('manage') < 0 && path.indexOf('create') < 0) {
      path = "/manage";
    }
    let auth = await Authorization.isAuthenticated();
    if (auth) {
      this.setState({
        isAuthenticated: true,
        original_location: path
      });
      this.props.history.replace(path);
    }
    else {
      this.setState({
        original_location: path
      })
      this.props.history.replace('/login');
    }
  }

  async logout() {
    await Authorization.logout();
    this.setState({
      isAuthenticated: false,
      project_id: 0,
      track_id: 0
    });
    this.props.history.replace('/login');
  }

  async login(email, password) {
    try {
      await Authorization.login(email, password);
      let project_id = await Survey.getProjectId();
      let track_id = await Survey.getTrackId();
      this.props.history.replace(this.state.original_location);
      this.setState({
        isAuthenticated: true,
        project_id: project_id,
        track_id: track_id
      })
    }
    catch(err) {
      console.log(err);
    }
  }

  async signup(email, password, dob) {
    try {
      await Authorization.register(email, password, dob);
      this.props.history.push('/login');
    }
    catch(err) {
    }
  }

  render() {
    return (
      <div className="App">
        <Header isAuthenticated={this.state.isAuthenticated} logout={this.logout} />
        <div className="Container">
          <div className="Sidebar">
            {this.state.isAuthenticated ? (
              <Navigation survey_id="" />
            ) : null}
          </div>
          <div className="Content">
            {this.state.isAuthenticated ? (
              <Switch>
                <Route exact path="/manage" render={(props) => (
                  <Surveys {...props} />
                )} />
                <Route path="/create" render={(props) => (
                  <Create {...props} />
                )} />
                <Route path="/manage/:survey_id" render={(props) => (
                  <Detail {...props} />
                )} />
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/login" render={(props) => (
                  <Login {...props} login={this.login} />
                )} />
                <Route exact path="/signup" render={(props) => (
                  <Signup {...props} signup={this.signup} />
                )} />
              </Switch>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);