const axios = require('axios').default;

const BE_URL = "https://survey.api.enumerate.ai/";
const AUTH_URL = "https://auth.enumerate.ai/";
const PIPE_URL = "https://pipeline.enumerate.ai/"

function uploadFile(header, url, file) {
  return axios({
    url: url,
    method: 'put',
    headers: header,
    data: file
  });
}

function loginAPI(email, password) {
  let URL = AUTH_URL + "login/";
  return axios({
    url: URL,
    method: 'post',
    data: {
      email: email,
      password: password
    }
  })
}

function authAPI(header) {
  let URL = AUTH_URL + "auth/";
  return axios({
    url: URL,
    method: 'post',
    headers: header
  })
}

function logoutAPI(header) {
  let URL = AUTH_URL + "logout/";
  return axios({
    url: URL,
    method: 'post',
    headers: header
  })
}

function registerAPI(email, password, dob) {
  let URL = AUTH_URL + "register/";
  return axios({
    url: URL,
    method: 'post',
    data: {
      city: "",
      dob: dob,
      email: email,
      gender: 1,
      latitude: 0,
      longitude: 0,
      userType: 1,
      password: password,
      userName: email
    }
  })
}

function getAllProject(header, payload) {
  let URL = BE_URL + "project/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createProject(header, payload) {
  let URL = BE_URL + "project/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllTrack(header, payload) {
  let URL = BE_URL + "track/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createTrack(header, payload) {
  let URL = BE_URL + "track/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllSurvey(header, payload) {
  let URL = BE_URL + "batch/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createSurvey(header, payload) {
  let URL = BE_URL + "batch/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllQuestion(header, payload) {
  let URL = BE_URL + "questions/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function createQuestion(header, payload) {
  let URL = BE_URL + "questions/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getAllVideo(header, payload) {
  let URL = BE_URL + "video/getall";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function getSignedURL(header, payload) {
  let URL = BE_URL + "video/upload";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  })
}

function addVideo(header, payload) {
  let URL = BE_URL + "video/";
  return axios({
    url: URL,
    headers: header,
    method: 'post',
    data: payload
  })
}

function updateVideoStatus(header, payload) {
  let URL = BE_URL + "video/";
  return axios({
    url: URL,
    method: 'put',
    headers: header,
    data: payload
  });
}

function startPipeline(header, payload) {
  let URL = PIPE_URL + "pipeline/";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function getAllPipeline(header, payload) {
  let URL = PIPE_URL + "pipeline/getAll";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

function getIndustryCategoryProduct(header, payload) {
  let URL = BE_URL + "industry/getall";
  return axios({
    url: URL,
    method: 'post',
    headers: header,
    data: payload
  });
}

export { 
  loginAPI, 
  registerAPI, 
  authAPI, 
  logoutAPI, 
  getAllProject, 
  getAllQuestion, 
  getAllSurvey, 
  getAllTrack, 
  createProject, 
  createQuestion, 
  createSurvey, 
  createTrack, 
  getAllVideo, 
  addVideo,
  getSignedURL,
  uploadFile,
  updateVideoStatus,
  startPipeline,
  getAllPipeline,
  getIndustryCategoryProduct
};