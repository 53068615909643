import { loginAPI, authAPI, logoutAPI, getAllProject, createProject, getAllTrack, createTrack, createSurvey, createQuestion, registerAPI, getAllVideo, getAllSurvey, getSignedURL, uploadFile, addVideo, updateVideoStatus, startPipeline, getAllPipeline, getIndustryCategoryProduct } from './API';

const statusMapping = {
  "0": "ongoing",
  "1": "processed"
}

const Authorization = (function() {
  var token = "";
  var user_id = "";
  var userType = "";
  var user_name = "";
  var isAuthenticated = false;
  var refreshAuth;

  function refreshToken() {
    refreshAuth = setInterval(function() {
      let header = {
        "Authorization": "Bearer " + token
      }
      authAPI(header).then(res => {
        let data = res.data;
        user_name = data.data.user_name;
        user_id = data.data.user_id;
      });
    }, 59*60*1000);
  }

  return {
    login: function(email, password) {
      return new Promise((resolve, reject) => {
        loginAPI(email, password).then(res => {
          let data = res.data;
          if (data.status === "success") {
            refreshToken();
            token = data.auth_token;
            window.sessionStorage.setItem('accessToken', token);
            userType = data.is_admin ? "ADMIN" : "CLIENT";
            isAuthenticated = true;
            resolve({
              success: true
            })
          }
        }).catch(err => {
          reject(err);
        })
      });
    },
    register: function(email, password, dob) {
      return new Promise((resolve, reject) => {
        registerAPI(email, password, dob).then(res => {
          let data = res.data;
          if (data.status === "success") {
            resolve({
              success: true
            })
          }
          reject();
        })
      });
    },
    isAuthenticated: async function() {
      if (isAuthenticated) {
        return isAuthenticated;
      }
      if (token === "" && window.sessionStorage.getItem('accessToken')) {
        token = window.sessionStorage.getItem('accessToken');
        try {
          let res = await authAPI(this.getHeader());
          if (res.data.status == "success") {
            await Survey.getProjectId();
            await Survey.getTrackId();
            await Survey.getAllSurveys();
            user_id = res.data.data.user_id;
            userType = res.data.data.admin ? "ADMIN" : "MEMBER";
            user_name = res.data.data.user_name;
            isAuthenticated = true;
          }
          else {
            isAuthenticated = false;
          }
        }
        catch(err) {
          isAuthenticated = false;
        }
      }
      return isAuthenticated;
    },
    logout: function() {
      clearInterval(refreshAuth);
      let header = {
        "Authorization": "Bearer " + token
      }
      logoutAPI(header).then(data => {
        isAuthenticated = false;
        token = "";
        window.sessionStorage.clear();
      })
    },
    getHeader: function() {
      return {
        "Authorization": "Bearer " + token
      }
    },
    getToken: function() {
      return token;
    }
  }
})();

const Survey = (function() {
  var project_id = false;
  var track_id = false;
  var surveyList = [];
  var old_survey_ids = [];
  var indCatProdMapping = {
    status: false,
  };

  return {
    getProjectId: async function() {
      if (project_id === false) {
        let header = Authorization.getHeader();
        let projects = await getAllProject(header, {"projectId": 0, "userId": 0});
        projects = JSON.parse(projects.data);
        if (projects.status === "success" && projects.value.length > 0) {
          project_id = projects.value[0]["id"];
        }
        else {
          await createProject(header, {
            "note": "",
            "title": "single project"
          });
          projects = await getAllProject(header, {"projectId": 0, "userId": 0});
          projects = JSON.parse(projects.data);
          if (projects.status === "success" && projects.value.length > 0) {
            project_id = projects.value[0]["id"];
          }
        }
        return project_id;
      }
      else {
        return project_id;
      }
    },
    getTrackId: async function() {
      if (track_id === false) {
        let header = Authorization.getHeader();
        let tracks = await getAllTrack(header, {
          "count": 0,
          "offset": 0,
          "projectId": project_id,
          "trackId": 0
        });
        tracks = JSON.parse(tracks.data);
        if (tracks.status === "success" && tracks.value.length > 0) {
          track_id = tracks.value[0]["id"];
        }
        else {
          await createTrack(header, {
            "brand": "",
            "category": 0,
            "industry": 0,
            "model": "",
            "note": "",
            "projectId": project_id,
            "title": "Track"
          });
          tracks = await getAllTrack(header, {
            "count": 0,
            "offset": 0,
            "projectId": project_id,
            "trackId": 0
          });
          tracks = JSON.parse(tracks.data);
          if (tracks.status === "success" && tracks.value.length > 0) {
            track_id = tracks.value[0]["id"];
          }
        }
        return track_id;
      }
      else {
        return track_id;
      }
    },
    getAllSurveys: async function() {
      let header = Authorization.getHeader();
      surveyList = [];
      old_survey_ids = [];
      try {
        let surveys = await getAllSurvey(header, {
          "batchId": 0,
          "trackId":track_id
        })
        surveys = JSON.parse(surveys.data);
        if (surveys.status == "success") {
          surveys.value.forEach((e) => {
            old_survey_ids.push(e.id);
            surveyList.push({
              title: e.name,
              survey_id: e.id,
              industry: e.industry || "",
              category: e.category || "",
              product: e.product || "",
              status: statusMapping[e.status],
              keyword: e.keyword || "",
              questions: e.questions,
              answers: {},
              pipeline_status: 0
            });
          });
        }
        else {
          surveyList = [];
        }
      }
      catch(err) {}
      return surveyList;
    },
    getSurvey: function(id) {
      let survey = {};
      for(let i = 0; i < surveyList.length; i++) {
        if (id == surveyList[i]["survey_id"]) {
          survey = Object.assign({}, surveyList[i]);
          break;
        }
      }
      return survey;
    },
    getPipeLine: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let res = await getAllPipeline(header, {
          batchId: parseInt(payload.survey_id),
          trackId: track_id
        });
        let data = JSON.parse(res.data);
        if (data.status == "success") {
          for(let i = 0; i < surveyList.length; i++) {
            if (payload.survey_id == surveyList[i]["survey_id"]) {
              surveyList[i]["pipleline_status"] = data.value[0].status_id;
            }
          }
          return {
            success: true,
            data: data
          }
        }
        else {
          return {
            success: false
          }
        }
      }
      catch(err) {
        return {
          success: false
        }
      }
    },
    createNewSurvey: async function(payload) {
      let header = Authorization.getHeader();
      try {
        await createSurvey(header, {
          keyword: payload.keyword,
          projectId: project_id,
          trackId: track_id,
          title: payload.title,
          industryId: payload.industryId,
          categoryId: payload.categoryId,
          productId: payload.productId
        });
        let prevOldArr = [ ...old_survey_ids ];
        await this.getAllSurveys();
        let s_id = "";
        for(let i = 0; i < surveyList.length; i++) {
          if (prevOldArr.indexOf(surveyList[i]["survey_id"]) < 0) {
            s_id = surveyList[i]["survey_id"];
            break;
          }
        }
        return {success: true, survey_id: s_id};
      }
      catch(err) {
        return {success: false};
      }
    },
    createNewQuestion: async function(payload) {
      let header = Authorization.getHeader();
      return createQuestion(header, {
        batchId: payload.survey_id,
        qType: 0,
        question: payload.question
      });
    },
    getAllVideos: async function(payload) {
      let header = Authorization.getHeader();
      try {
        let res = await getAllVideo(header, {
          batchId: payload.survey_id,
          projectId: project_id,
          questionId: payload.question_id,
          trackId: track_id,
          videoId: 0
        });
        let data = JSON.parse(res.data);
        let ans = [];
        if (data.status === "success") {
          data.value.forEach((e) => {
            ans.push({
              id: e.videoId,
              url: e.videoUrl,
              status: e.status,
              thumbnail: false
            })
          })
        }
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id == surveyList[i]["survey_id"]) {
            surveyList[i]['answers'][payload.question_id] = ans;
            break;
          }
        }
        return [...ans];
      }
      catch(err) {
        return {
          status: 'fail'
        }
      }
    },
    uploadVideo: async function(payload) {
      try {
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id == surveyList[i]["survey_id"]) {
            if (surveyList[i]['answers'].hasOwnProperty(payload.question_id)) {
              surveyList[i]['answers'][payload.question_id].push({
                id: payload.temp_vid_id,
                url: "",
                status: "-1",
                thumbnail: false
              });
            }
            else {
              surveyList[i]['answers'][payload.question_id] = [{
                id: payload.temp_vid_id,
                url: "",
                status: "-1",
                thumbnail: false
              }];
            }
            break;
          }
        }
        let header = Authorization.getHeader();
        let signedURLResp = await getSignedURL(header, {
          batchId: payload.survey_id,
          questionId: payload.question_id,
          fileName: payload.fileName
        });
        let data = JSON.parse(signedURLResp.data);
        if (data.status != "success") {
          throw "did not get signed url";
        }
        let url = data.value.substring(0, data.value.indexOf('?'));
        let fileHeader = {};
        if (url.indexOf('.mp4') >= 0) {
          fileHeader['Content-Type'] = 'video/mp4';
        }
        else if (url.indexOf('.webm') >= 0) {
          fileHeader['Content-Type'] = 'video/webm';
        }
        await uploadFile(fileHeader, data.value, payload.file);
        let associate = await addVideo(header, {
          batchId: payload.survey_id,
          moduleType: "",
          projectId: project_id,
          questionId: payload.question_id,
          title: "",
          trackId: track_id,
          transcription: "",
          appType: 2,
          videoUrl: url
        });
        let status = JSON.parse(associate.data).status;
        if (status == "success") {
          return {
            success: true,
            url: url
          };
        }
        else {
          throw 'association fail'
        }
      }
      catch(err) {
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id == surveyList[i]["survey_id"]) {
            let newAns = [];
            for(let j = 0; j < surveyList[i]["answers"][payload.question_id].length; j++) {
              if (payload.temp_vid_id !== surveyList[i]["answers"][payload.question_id][j]["id"]) {
                newAns.push(surveyList[i]["answers"][payload.question_id][j]);
              }
            }
            surveyList[i]["answers"][payload.question_id] = newAns;
            break;
          }
        }
        return {
          success: false
        }
      }
    },
    approveVideo: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let data = await updateVideoStatus(header, {
          moduleType: "",
          title: "",
          transcription: "",
          videoId: payload.videoIds,
          videoStatus: 1,
          videoUrl: ""
        });
        if (JSON.parse(data.data).status != "success") {
          throw 'could not update video status';
        }
        for(let i = 0; i < surveyList.length; i++) {
          if (payload.survey_id == surveyList[i]["survey_id"]) {
            for(let j = 0; j < surveyList[i]["answers"][payload.question_id].length; j++) {
              if (payload.videoIds.indexOf(surveyList[i]["answers"][payload.question_id][j]["id"]) >= 0) {
                surveyList[i]["answers"][payload.question_id][j]["status"] = "1";
              }
            }
            break;
          }
        }
        return {
          success: true
        }
      }
      catch(err) {
        return {
          success: false
        }
      }
    },
    processVideos: async function(payload) {
      try {
        let header = Authorization.getHeader();
        let piperesp = await startPipeline(header, {
          batchId: parseInt(payload.survey_id),
          speedId: 1,
          trackId: track_id,
          versionId: 3
        });
        let pipeData = JSON.parse(piperesp.data);
        if (pipeData.status == "success") {
          for(let i = 0; i < surveyList.length; i++) {
            if (payload.survey_id == surveyList[i]["survey_id"]) {
              surveyList[i]["pipleline_status"] = 1;
              for(let j = 0; j < surveyList[i]["answers"][payload.question_id].length; j++) {
                if (surveyList[i]["answers"][payload.question_id][j]["status"] == "1") {
                  surveyList[i]["answers"][payload.question_id][j]["status"] = "2";
                }
              }
              break;
            }
          }
          return {
            success: true
          }
        }
        else {
          return {
            success: false
          }
        }
      }
      catch(err) {
        return {
          success: false
        }
      }
    },
    getIndustryCategoryProduct: async function(payload) {
      if (indCatProdMapping.status) {
        return indCatProdMapping;
      }
      try {
        let header = Authorization.getHeader();
        let structure = await getIndustryCategoryProduct(header, {
          categoryId: 0,
          industryId: 0,
          productId: 0
        })
        let data = JSON.parse(structure.data);
        indCatProdMapping.industry = {};
        data.industry.forEach((e) => {
          indCatProdMapping.industry[e.id.toString()] = {
            id: e.id,
            name: e.name,
            category: {}
          }
        });
        data.category.forEach((e) => {
          indCatProdMapping.industry[e.industryId.toString()].category[e.id.toString()] = {
            id: e.id,
            name: e.name,
            product: {}
          }
        })
        indCatProdMapping.status = true;
        return indCatProdMapping;
      }
      catch(err) {
        return indCatProdMapping;
      }
    }
  }
})();

const Questionaire = (function() {
  var Survey = {
    title: "",
    id: null,
    industry: "",
    category: "",
    product: "",
    questions: []
  };

  return {
    getSurvey: async (survey_id, track_id, project_id) => {
      if (Survey.questions.length == 0) {
        let header = Authorization.getHeader();
        Survey.id = survey_id;
        try {
          let surveys = await getAllSurvey(header, {
            "batchId": survey_id,
            "trackId":track_id
          })
          surveys = JSON.parse(surveys.data);
          if (surveys.status == "success") {
            Survey.title = surveys.value[0]['name'];
            Survey.industry = surveys.value[0]['industry'];
            Survey.category = surveys.value[0]['category'];
            Survey.product = surveys.value[0]['product'];
            surveys.value[0]['questions'].forEach((e) => {
              Survey.questions.push({
                id: e.id,
                question: e.question,
                answer: {
                  url: "",
                  status: "0"
                }
              })
            })
          }
          else {
          }
        }
        catch(err) {}
      }
      return Survey;
    },
    submitAnswer: async (payload) => {
      try {
        for(let i = 0; i < Survey.questions.length; i++) {
          if (payload.question_id == Survey.questions[i]["id"]) {
            Survey.questions[i]["answer"] = {
              url: "",
              status: "1"
            }
            break;
          }
        }
        let header = Authorization.getHeader();
        let signedURLResp = await getSignedURL(header, {
          batchId: Survey.id,
          questionId: payload.question_id,
          fileName: payload.fileName
        });
        let data = JSON.parse(signedURLResp.data);
        if (data.status != "success") {
          throw "did not get signed url";
        }
        let url = data.value.substring(0, data.value.indexOf('?'));
        let fileHeader = {};
        if (url.indexOf('.mp4') >= 0) {
          fileHeader['Content-Type'] = 'video/mp4';
        }
        else if (url.indexOf('.webm') >= 0) {
          fileHeader['Content-Type'] = 'video/webm';
        }
        await uploadFile(fileHeader, data.value, payload.file);
        let associate = await addVideo(header, {
          batchId: Survey.id,
          moduleType: "",
          projectId: payload.project_id,
          questionId: payload.question_id,
          title: "",
          trackId: payload.track_id,
          transcription: "",
          appType: 1,
          videoUrl: url
        });
        let status = JSON.parse(associate.data).status;
        if (status == "success") {
          for(let i = 0; i < Survey.questions.length; i++) {
            if (payload.question_id == Survey.questions[i]["id"]) {
              Survey.questions[i]["answer"] = {
                url: url,
                status: "2"
              }
              break;
            }
          }
          return {
            success: true,
            url: url
          };
        }
        else {
          throw 'association fail'
        }
      }
      catch(err) {
        for(let i = 0; i < Survey.questions.length; i++) {
          if (payload.question_id == Survey.questions[i]["id"]) {
            Survey.questions[i]["answer"] = {
              url: "",
              status: "-1"
            }
            break;
          }
        }
        return {
          success: false
        }
      }
    }
  }
})();

export { Authorization, Survey, Questionaire };