import React from 'react';
import { Link } from 'react-router-dom';
import './Item.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,  } from '@fortawesome/free-solid-svg-icons';
import { IoIosPaper } from 'react-icons/io';

export default (props) => {
  let color = "#98a6ad";
  if (props.status === "processed") {
    color = "#0acf97"
  }

  return (
    <div className="Item" style={{borderLeftColor: color}}>
      <div className="Line">
        <div className="Title">{props.title}</div>
      </div>
      <div className="Line">
        <div className="Tag">
          {props.industry} - {props.category}
        </div>
        <div className="Action-Survey">
          {/* <Link to={`/create/${props.id}`}>
            <div className="Button" style={{backgroundColor: "#f0ad4e"}}>
              <FontAwesomeIcon icon={faEdit} className="Icon" /> Edit
            </div>
          </Link> */}
          <Link to={`/manage/${props.id}`}>
            <div className="Button" style={{backgroundColor: "#59a5d8"}}>
              <IoIosPaper className="Icon" size="1rem" /> Detail
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}